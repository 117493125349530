.centerContent {
    text-align: center;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 1.5rem;
  }
  .tw-main-div {
    width: auto;
    padding: 2.5rem;
  }
  .react-github-calendar__calendar { 
    width: 90%; 
  }
  
  .timeline-Header-title {
    font-size: 56px;
    margin-left: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    font-weight: 100 !important;
  }
  
  @media (max-width: 768px) {
    .timeline-Header-title{
      font-size: 30px;
      margin-bottom: 10px;
      text-align: center;
      margin-left: 0;
    }
    .centerContent {
      text-align: center;
      width: auto;
      display: block;
      margin: 0 auto;
      padding: 1.5rem;
    }
    .react-github-calendar__calendar { 
      width: 100%;
    }
  }
  